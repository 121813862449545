import Lang from "./Lang";

//STATIC, no call
class FormService{
	static validateField(fieldObj,value){
		return this.validate(fieldObj.validation,value);
	}

	static validate(valiObj,value){
		let ret="";
		if(valiObj){
			for(let ruleName in valiObj){
				let ruleValue=valiObj[ruleName];
				switch(ruleName){
					case "notEmpty":
						if(!value.length){
							ret="field_cannot_be_empty";
						}
					break;
					case "notZero":
						if(Number(value)===0){
							ret="non_zero_value";
						}
					break;
					case "numberPositive":
						if(value && (isNaN(value) || Number(value)<=0)){
							ret="positive_value";
						}
					break;
					case "isEmail":
						if(!this.isValidEmail(value)){
							ret="invalid_email";
						}
					break;
					case "length":
						if(value.length!==ruleValue){
							ret="exact_length";
							ret=Lang.errorTexts[ret]?Lang.errorTexts[ret].replace("[1]",ruleValue):"";
						}
					break;
					case "minLength":
						console.log(value.length,ruleValue);
						if(value.length<ruleValue){
							ret="min_length";
							ret=Lang.errorTexts[ret]?Lang.errorTexts[ret].replace("[1]",ruleValue):"";
						}
					break;
					case "checked":
						if(value!==true){
							ret="checked";
						}
					break;
					case "activationCode":
						let reg=/^\w{3}-\w{5}-\w{3}$/g;
						if(!reg.test(value)){
							ret="activation_code";
						}
					break;
					default:
					break;
				}
				if(ret){break;}
			}
		}
		
		return Lang.errorTexts[ret]	|| ret;
	}
	
	static isValidEmail(s){
		var re = /\S+@\S+\.\S+/;
    return re.test(s);
	}
	static hasErrors(errors){
		return Object.keys(errors).filter(key=>errors[key].length).length;
	}
	static fieldsHoistLang(fields){
		for(let f of fields){
			if(f.langHoisted){return;}
			["placeholder","label"].forEach(fieldName=>{
				let langField=fieldName+"Lang";
				if(f[langField]){
					// console.log(fieldName,langField,f[langField],Lang[f[langField][0]]);
					f[fieldName]=Lang[f[langField][0]][f[langField][1]];
					delete f[langField];
				}
			});
			f.langHoisted=true;
		}
	}
	static fieldsHoist(fields,fieldsObj){
		fields.forEach(f=>{
			f.value=fieldsObj[f.name][0];
			f.chFunc=fieldsObj[f.name][1];
		});
		this.fieldsHoistLang(fields);
	}
	static fieldChangeHooks(fieldObj,value,fieldEl){
		if(fieldObj.toUpperCase){
			value=value.toUpperCase();
			fieldEl.value=value;
		}
		if(fieldObj.toLowerCase){
			value=value.toLowerCase();
			fieldEl.value=value;
		}
		fieldObj.chFunc(value);
	}

	static validateEntireFormHooks(fields,chErrorFunc,retErrors=false){
		let errors={};
		fields.forEach(fObj=>{
			let error=FormService.validateField(fObj,fObj.value);
			errors[fObj.name]=error;
		});
		chErrorFunc(errors);
		return (retErrors?errors:this.hasErrors(errors));
	}

	static isSubmittable(component){
		//component.isDirty && 	
		return (!this.hasErrors(component.state.errors));
	}

	static getLocalDate(str=""){
		let date = new Date(str);
		if(!date.getTime()){
			date=new Date();
		}
		date.setMinutes(date.getMinutes()+date.getTimezoneOffset());
 		return date;
	}
	static dateToStr(date){	//2004-05-28
		let d=date.getDate();
		let m=date.getMonth()+1;
		return `${date.getFullYear()}-${m<10?"0"+m:m}-${d<10?"0"+d:d}`;
	}
}



export default FormService;