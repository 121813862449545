import React, { useEffect, useState, useContext } from 'react';
import {useHistory} from 'react-router-dom';
import AppContext from "../AppContext";
import { Link } from "react-router-dom";
import { Api, server, Config,Lang } from "../services/Services";
import {TopNotif} from "./utils/Utils";

const anim={doRun:true,inter:null,pos:0};

const Home = () => {
	const context=useContext(AppContext);
	const history=useHistory();
	// const [pageObj, pageObjUpd] = useState(null);
	// const [doAnimate, doAnimateUpd] = useState(true);
	const user=context.UserService.user;

	const load = async () => {
		// let obj = await Api.getCmsPage("home-banner");
		// if (obj && obj.page) {
		// 	// let parent=MenuItems.find(it=>it.children && it.children.find(ch=>ch.link==="/pages/"+page));
		// 	obj.page.content = obj.page.content.replace(/src="/ig, "src=\"" + server);
		// 	let pageObj = { ...obj.page };

		// 	pageObjUpd(pageObj);
		// }
	}

	useEffect(() => {
		load();
		if(anim.doRun){
			doAnimateUpd(anim.doRun);
		}
		return ()=>{clearInterval(anim.inter)}
	}, []);

	const animMove=()=>{
		anim.pos++;
		let el=window.document.getElementById("home-bg");
		if(el){
			el.style.backgroundPositionX=anim.pos+"px";
		}
	}

	const doAnimateUpd=(doRun)=>{
		anim.doRun=doRun;
		if(anim.doRun){
			anim.inter=setInterval(()=>animMove(),50);
		}
		else{
			clearInterval(anim.inter);
		}
	}

	const play=()=>{
		if(user){
			context.UserService.goToGames();
		}
		else{
			console.log("hi");
			context.UserService.opLoginScreen(true);
		}
	}
	const parents=()=>{
		if(user){
			history.push("/charts");
		}
		else{
			context.UserService.opLoginScreen(false);
		}
	}
	const buyBtn=()=>{
		if(user){
			history.push("/products/0");
		}
		else{
			window.gtag_report_conversion();
			setTimeout(()=>{
				window.location=Config.shop_url;
			},100);
		}
	}

	// if(!pageObj){return null;}
	return (
		<section  className="home" >
			<TopNotif className="non-app">
				<p className=""> {user?Lang.home.header_text1:Lang.home.welcome_message} </p>
			</TopNotif>
			{/* +(doAnimate?"doAnimate":"") */}
			<div className={"home-bg "} id="home-bg" >
				<div className="overlay"  onClick={()=>doAnimateUpd(!anim.doRun)}  >
					<div className="content">
						<Link to="/"  >
							<img alt="" className="home-logo" src={Config.imagesFolder+"/logo2.png"} />
						</Link>
						<h2 dangerouslySetInnerHTML={{__html:Lang.home.intro_text}}></h2>
						<img alt="" className="sitting-jumbee" onClick={()=>play()} src="/assets/images/app-play-btn.png" />
						<div className="btns" >
							<button className="red non-app" onClick={()=>buyBtn()}>{Lang.home[user?"buy":"buy_keyboard"]}</button>
							<button className={"white "+(user?"":"only-app")} onClick={()=>play()} >{Lang.home.play_now}</button>
							<button className="non-app"  onClick={() => parents()} >{Lang.home[user?"intro_parents":"intro_play"]} &gt;</button>
						</div>
						<img alt="" className="keyboard" src={Config.imagesFolder+"/play-btn.png"} />
					</div>
				</div>
			</div>
		</section>
	
	)
}

export default Home;
