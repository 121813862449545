import React, { useState } from 'react';
import { Link } from "react-router-dom";
import { Config, Lang } from "../../services/Services";
import UserMenu from "./UserMenu";



const Menu = () => {
	const [isOpen, isOpenUpd] = useState(false);
	const [isOpenLang, isOpenLangUpd] = useState(false);
	const curLangId=Config.langId;

	return (
		<header>
			<div className="start-side">
				<div>
					<i onClick={()=>isOpenUpd(!isOpen)} className="fa fa-bars"></i>
					{isOpen &&
					<div className="dropdown"    >
						<ul onClick={()=>isOpenUpd(false)} >
							<li><Link to="/">{Lang.navigation.home}</Link></li> 
							<li><Link  to="/pages/terms-of-use">{Lang.top_of_page.terms_of_use}</Link></li> 
							<li><Link to="/pages/common-questions">{Lang.top_of_page.f_a_q}</Link></li> 
							<li><Link to="/pages/contact-us">{Lang.top_of_page.contact_us}</Link></li> 
							<li><Link to="/products/0">{Lang.navigation.store}</Link></li> 
						</ul>
					</div>
					}
				</div>
				<div>
					<Link to="/"> 
						<img alt="" src={Config.imagesFolder+"/logo.png"} className="logo" />
					</Link>
				</div>
				
			</div>

			<UserMenu />

			<div className="menu lang">
				<button onClick={()=>isOpenLangUpd(!isOpenLang)} >
					<img alt="" className="only-lg" src="/assets/images/globe.png" />
					<span className="only-lg" >{Lang.langs[curLangId] || ""} </span>
					<span className="non-lg langIcon" style={{backgroundImage: `url(/assets/images/flags/${curLangId}.png)`}}  ></span>
					<i className="fa fa-angle-down"></i>
				</button>

				{isOpenLang &&
				<div className="dropdown"    >
					<ul  onClick={()=>isOpenLangUpd(false)} >
						{Object.keys(Lang.langs).map(langId=><li key={langId} onClick={()=>Lang.chLang(langId)} >
							<span className="langIcon" style={{backgroundImage: `url(/assets/images/flags/${langId}.png)`}} ></span>
							<span className="marg-bef" >{Lang.langs[langId]}</span>
							</li>)}   							
					</ul>
				</div>
				}
			</div>
		</header>		
	);

}

export default Menu;