import React from 'react';
import DatePicker from "react-datepicker";
import {Error,SelectGen} from "./Utils";
import {FormService} from "../../services/Services";

const InputDiv=props=>{
	//fieldObj,error,value,fieldChange
	let {fieldChange=FormService.fieldChangeHooks,fieldObj,value,error,postLabelContent="",doLabel=false}=props;

	if(value===undefined){
		value=fieldObj.value;
	}
	if(fieldObj.type==="date"){
		value=FormService.getLocalDate(value || "");
	}
	const errCls=(error?"error":"");

	return (
		<>
			{doLabel && <label>{fieldObj.label}</label>}
			{fieldObj.type==="select" &&
				<SelectGen {...props} />
			}
			{fieldObj.type==="checkbox" &&
				<>
					<input name={fieldObj.name} type="checkbox" className={errCls}   onChange={(e)=>fieldChange(fieldObj,e.target[(fieldObj.type==="checkbox"?"checked":"value")])}  defaultChecked={fieldObj.value===true}  /> 
					<label className="ml-2" >{fieldObj.label}</label>
				</>
			}
			{postLabelContent}
			{(fieldObj.type!=="select" && fieldObj.type!=="date" && fieldObj.type!=="checkbox") &&
				<input name={fieldObj.name} 
				type={fieldObj.type || "text"} 
				className={"form-control "+errCls} 
				placeholder={fieldObj.placeholder}  
				onChange={(e)=>fieldChange(fieldObj,e.target.value,e.target)} defaultValue={value || ""} 
				 />	
			}
			{fieldObj.type==="date" &&
				<DatePicker selected={value} onChange={date=>fieldChange(fieldObj,FormService.dateToStr(date))}
				className={"form-control "+errCls} placeholder={fieldObj.placeholder} 
				/>	
			}
			<Error err={error} />
			</>
	);
	
}

export default InputDiv;
