import React,{useContext,useState} from 'react';
import {useHistory} from "react-router-dom";
import AppContext from "../../AppContext";
import { GenForm } from '../Hocs/Hocs';
import {FormService,Api,ModalService,Lang} from "./../../services/Services";
import {BaseSubmit,InputDiv,RegiBtns} from "./../utils/Utils";
import ForgotPassword from "./ForgotPassword";


const fields=[
	{name:"email",label:"Email",validation:{isEmail:true,notEmpty:true},placeholderLang:["activate_login","label_email"],toLowerCase:true},
	{name:"password",label:"Password",type:"password",validation:{notEmpty:true},placeholderLang:["activate_login","label_password"]},
];
const fieldsObj={};
const apiFunc=async()=>await Api.login(fieldsObj.email[0],fieldsObj.password[0]);


const Login=({errors,submit,servError})=>{
	const context=useContext(AppContext);
	const history=useHistory();
	const [isForgot,isForgotUpd]=useState(false);

	fieldsObj.email=useState("");
	fieldsObj.password=useState("");


	const submitFunc=async ()=>{
		let res=await submit();
		if(res && res.account){
			context.UserService.updateInfo({user:res.account,curPlayerId:res.playerId});
			// history.push("/");
			ModalService.closeBlueCover();
			if(context.UserService.isGoToPlay){
				context.UserService.goToGames();
			}
		}
	}
	
	FormService.fieldsHoist(fields,fieldsObj);
	
	if(context.UserService.user){return null;}
		
	if(isForgot){return <ForgotPassword closeForgot={()=>isForgotUpd(false)} />}
	return (
		<>
		<ul className="form">
			{
				fields.map(fieldObj=><li key={fieldObj.name}><InputDiv   fieldObj={fieldObj}  error={errors[fieldObj.name]}  /></li>)
			}
			<li>
				<p>
					<span className="form-p underline pointer" onClick={()=>isForgotUpd(true)} >{Lang.activate_login.forgot_your_password}</span>
				</p>
			</li>
		
		</ul>
		<RegiBtns servError={servError} >
			<BaseSubmit onClick={submitFunc} label={Lang.activate_login.butten_login}  />
		</RegiBtns>

		</>

	);
}

export default GenForm(Login,{fields,apiFunc,langFail:["errorTexts","login_fail"],langSuc:["errorTexts","login_suc"]});